import {ITeilnehmer} from "./ITeilnehmer.interface";
import {INachweisvorlage} from "./INachweisvorlage.interface";
import {ErinnerungZeit} from "./erinnerungZeit.enum";
import {GueltigkeitsdauerUnit} from "./gueltigkeitsdauerUnit.enum";

export interface INachweis {
  id: number
  teilnehmer: ITeilnehmer
  vorlage: INachweisvorlage
  erstellt: Date
  ausstellungsdatum: Date
  bemerkung: string
  dateiname: string
}

export function isNachweis(obj: Object): obj is INachweis {
  function d(value: string): boolean {
    return value !== undefined;
  }

  return obj &&
    d(obj['id']) &&
    d(obj['teilnehmer']) &&
    d(obj['vorlage']) &&
    d(obj['erstellt']) &&
    d(obj['ausstellungsdatum']) &&
    d(obj['bemerkung']) &&
    d(obj['dateiname']);
}

export function formateErinnerung(erinnerungZeit: ErinnerungZeit) {
  switch (erinnerungZeit) {
    case ErinnerungZeit.NONE:
      return "Keine Erinnerung"
    case ErinnerungZeit.ON_TIME:
      return "Bei Ablauf";
    case ErinnerungZeit.WEEK_1:
      return "Eine Woche vorher";
    case ErinnerungZeit.WEEK_2:
      return "Zwei Wochen vorher";
    case ErinnerungZeit.MONTH_1:
      return "Ein Monat vorher";
    case ErinnerungZeit.MONTH_2:
      return "Zwei Monate vorher";
    case ErinnerungZeit.MONTH_3:
      return "Drei Monate vorher";
    case ErinnerungZeit.MONTH_4:
      return "Vier Monate vorher";
    case ErinnerungZeit.MONTH_5:
      return "Fünf Monate vorher";
    case ErinnerungZeit.MONTH_6:
      return "Sechs Monate vorher";
  }
}

export function formatGueltigkeit(v: INachweisvorlage) {
  if(v.gueltigkeitsdauerVal <= 0) {
    return "---"
  }

  let unit = "";
  switch (v.gueltigkeitsdauerUnit) {
    case GueltigkeitsdauerUnit.TAG:
      unit = "Tage";
      break;
    case GueltigkeitsdauerUnit.WOCHE:
      unit = "Wochen"
      break;
    case GueltigkeitsdauerUnit.MONAT:
      unit = "Monate"
      break;
    case GueltigkeitsdauerUnit.JAHR:
      unit = "Jahre"
      break;
  }

  return v.gueltigkeitsdauerVal + " " + unit;
}
