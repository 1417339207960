<div class="row d-flex d-md-none">
  <div class="col-12 text-center">
    Bitte das Handy in Querformat drehen, um Nachweise bearbeiten zu können.
  </div>
</div>
<div class="row d-none d-md-flex">
  <div class="col-2 col-lg-3">
  <b>Typ</b>
  </div>
  <div class="col-2">
    <b>Gültigkeits&shy;dauer</b>
  </div>
  <div class="col-4">
    <b>Bemerkung</b>
  </div>
  <div class="col-4 col-lg-3 text-right ">
    <button *ngIf="!readonly" class="btn pcButton" (click)="onAddClick()" [disabled]="vorlagen.length === 0"><span *ngIf="!loading; else loadingIcon">Nachweis hinzufügen</span></button>
  </div>
</div>
<!-- Neuer Nachweiseditor -->
<div  *ngIf="create" class="row d-none d-md-flex">
  <div class="col-12">
    <ng-container [ngTemplateOutlet]="editor"></ng-container>
  </div>
</div>
<!-- Ein Element -->
<ng-container *ngFor="let nachweis of sortedNachweise">
  <div class="row d-none d-md-flex py-2" [class.edit-color]="editId===nachweis.id || details === nachweis.id">
    <div class="col-2 col-lg-3">
      {{nachweis.vorlage.name}}
    </div>
    <div class="col-2">
      <span *ngIf="!nachweis.vorlage.gueltigkeitsdauerUnit">
        Seit {{ nachweis.ausstellungsdatum | date:'shortDate'}} (läuft nicht ab)
      </span>
      <span *ngIf="nachweis.vorlage.gueltigkeitsdauerUnit">
        {{ nachweis.ausstellungsdatum | date:'shortDate'}} bis {{ endDateRaw(nachweis) | date:'shortDate'}}
      </span>
      <span *ngIf="isAbgelaufen(nachweis)" class="d-inline-block bg-danger text-white px-2 mx-2 rounded">Abgelaufen</span>
      <span *ngIf="isErinnerung(nachweis) && !isAbgelaufen(nachweis)" class="d-inline-block bg-warning px-2 mx-2 rounded">Läuft ab</span>
    </div>
    <div class="col-4">
      {{nachweis.bemerkung}}
    </div>
    <div class="col-4 col-lg-3 d-flex flex-row-reverse flex-wrap align-items-start">
      <button *ngIf="!readonly" class="btn pcButton ml-2" (click)="delete(nachweis)"><i class="fas fa-trash"></i></button>
      <button *ngIf="!readonly" class="btn pcButton ml-2" (click)="details = -1; onEditClick(nachweis)"><i class="fas fa-edit"></i></button>
      <button class="btn pcButton ml-2" *ngIf="details === nachweis.id" (click)="details = -1"><i class="fas fa-eye-slash"></i></button>
      <button class="btn pcButton ml-2" *ngIf="details !== nachweis.id" (click)="details = nachweis.id; editId = -1"><i class="fas fa-eye"></i></button>
      <button *ngIf="!!nachweis.dateiname" class="btn pcButton ml-2" (click)="download(nachweis, $event)"><i class="fas fa-download"></i></button>
    </div>
  </div>
  <ng-container *ngIf="nachweis.id == details">
    <div class="row d-none d-md-flex edit-color">
      <div class="col-12 d-flex flex-row flex-wrap">
        <div class="px-2 py-1 mr-2 mb-1 rounded primaryBgColor text-white"><b>Eingesehen am:</b> {{nachweis.erstellt | date:'shortDate'}}</div>
      </div>
    </div>
    <div class="row d-none d-md-flex edit-color " *ngIf="nachweis.dateiname">
      <div class="col-12">
        <app-file-preview [fileName]="nachweis.dateiname" [file]="getFile(nachweis)"></app-file-preview>
      </div>
    </div>
  </ng-container>
  <div class="row d-none d-md-flex edit-color ">
    <div class="col-12">
      <ng-container *ngIf="nachweis.id === editId && !create" [ngTemplateOutlet]="editor"></ng-container>
    </div>
  </div>
</ng-container>
<ng-template #editor>
  <tr [class.edit-color]="!create" class="">
    <td colspan="4">
      <div class="d-flex flex-row w-100">
        <div class="row flex-grow-1">
          <!-- Vorlage (Auswahl) -->
          <div class="col-12 mt-2" *ngIf="create" >
            <p>Hier können Nachweise für einen Teilnehmer hinterlegt werden. Anhand des Nachweistyps kann der jeweilige Nachweis hier gespeichert werden. Sobald dieser abläuft wird der Stammesleiter über Mail informiert.</p>
          </div>
          <div class="col-12 input-group mb-2">
            <div class="input-group-append">
              <span class="input-group-text fixedWidth pflichtfeld ">Nachweistyp</span>
            </div>
            <select [(ngModel)]="editItem.vorlage" class="form-control" [disabled]="!create">
              <ng-container *ngFor="let v of vorlagen">
                <option *ngIf="!v.disabled || (editItem.vorlage.id === v.id)" [ngValue]="v">{{v.name}}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-12 d-flex flex-row flex-wrap mb-2">
            <div class="px-2 py-1 mr-2 mb-1 rounded" [class.secondaryBgColor]="create" [class.primaryBgColor]="!create" [class.text-white]="!create">
              <b>Information:</b> {{editItem.vorlage.beschreibung}}
            </div>
            <div class="px-2 py-1 mr-2 mb-1 rounded" [class.secondaryBgColor]="create" [class.primaryBgColor]="!create" [class.text-white]="!create">
              <b>Gültigkeitsdauer:</b> {{dauer(editItem.vorlage)}}
            </div>
            <div class="px-2 py-1 mr-2 mb-1 rounded" [class.secondaryBgColor]="create" [class.primaryBgColor]="!create" [class.text-white]="!create">
              <b>Dokumentenupload:</b> {{uploadRequired(editItem.vorlage)}}
            </div>
            <div class="px-2 py-1 mr-2 mb-1 rounded" [class.secondaryBgColor]="create" [class.primaryBgColor]="!create" [class.text-white]="!create">
              <b>Erinnerung:</b> {{formateErinnerung(editItem.vorlage.erinnerungZeit)}}
            </div>
          </div>

          <div class="col-12 input-group mt-2">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="setValidUntil" [disabled]="!editItem.vorlage.gueltigkeitsdauerUnit">
              <label class="custom-control-label" for="customSwitch1">
                Ausstellungsdatum oder Ablaufdatum angeben:
                <b>{{setValidUntil ? 'Ablaufdatum' : 'Ausstellungsdatum' }}</b>
              </label>
            </div>
          </div>

          <!-- Ausstellungsdatum -->
          <div class="col-12 input-group mb-2">
            <div class="input-group-append">
              <span class="input-group-text fixedWidth pflichtfeld">{{setValidUntil ? 'Ablaufdatum' : 'Ausstellungsdatum' }}</span>
            </div>

            <input type="date" class="form-control" [(ngModel)]="dateInputData">
            <div class="input-group-prepend">
              <span class="input-group-text fixedWidth">{{setValidUntil ? 'Ausgestellt am: ' + ausstellungsdatum : 'Gültig bis: ' + endDate(editItem) }}</span>
            </div>
          </div>

          <!-- Datum Einsichtnahme -->
          <div class="col-12 input-group mb-2">
            <div class="input-group-append">
              <span class="input-group-text fixedWidth pflichtfeld">Eingesehen am:</span>
            </div>

            <input type="date" class="form-control" [(ngModel)]="dateCheckData">
          </div>

          <!-- Bemerkung -->
          <div class="col-12 input-group my-2">
            <div class="input-group-append">
              <span class="input-group-text fixedWidth">Bemerkung</span>
            </div>
            <textarea class="form-control" [(ngModel)]="editItem.bemerkung"></textarea>
          </div>

          <!-- File Upload -->
          <div class="col-12 input-group my-2" *ngIf="editItem.vorlage.uploadRequirement !== UploadRequirement.NEVER">
            <div class="input-group-append">
              <span class="input-group-text" [class.pflichtfeld]="editItem.vorlage.uploadRequirement === UploadRequirement.REQUIRED">Datei</span>
            </div>
            <div class="custom-file">
              <input type="file" class="custom-file-input"
                     name="file" id="upload" (change)="handleFileUpload($event.target.files)"
                     accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.docx,.csv">>
              <label class="custom-file-label" for="upload">{{editItem.dateiname || 'Datei auswählen'}}</label>
            </div>
          </div>
        </div>
        <div class="flex-grow-0 ml-2 my-2 d-flex flex-column-reverse">
          <!-- TODO Disable wenn pflichtfelder fehlen -->
          <button class="btn pcButton" (click)="save()" [disabled]="loading">
            <span *ngIf="!create">Änderungen Speichern</span>
            <span *ngIf="create">Speichern</span>
          </button>
        </div>
      </div>
    </td>
  </tr>
</ng-template>
<ng-template #loadingIcon>
  <i class="fas fa-spin fa-circle-notch"></i>
</ng-template>
