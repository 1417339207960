<div class="row rowUeberschrift">
  <div class="col-12 text-center">
    <h1 class="text-center">Nachweisvorlagen</h1>
    <p class="text-center">
      Diese Vorlagen ermöglichen es, spezifische Anforderungen für Nachweise festzulegen, darunter: <br>
      <br>
      <strong>Name des Nachweises</strong>: Klare Bezeichnung für den Nachweis zur einfachen Identifizierung.<br>
      <strong>Beschreibung</strong>: Kurze Erläuterung des Zwecks und der Anforderungen des Nachweises.<br>
      <strong>Gültigkeitsdauer</strong>: Festlegung, wie lange der Nachweis gültig ist, entweder unbegrenzt oder für
      einen bestimmten Zeitraum.<br>
      <strong>Erinnerungsvorlauf</strong>: Optionale Benachrichtigung der Stammesleiter vor Ablauf ihres Nachweises.<br>
      <strong>Dokumentenupload</strong>: Möglichkeit für Stammesleiter, erforderliche Dokumente hochzuladen, entweder
      optional oder als Pflicht.<br>
      <br>
      Diese Vorlagen können nur von Super-Usern gepflegt werden, um die Datenintegrität zu gewährleisten, und können
      nicht gelöscht werden. Stammesleitern stehen die erstellten Vorlagen zur Auswahl, um relevante Nachweise wie die
      Jugendleiter*innen-Card (Juleica) oder Führungszeugnisse hochzuladen.
    </p>
  </div>

  <table class="table">
    <thead>
    <tr>
      <th>Name</th>
      <th>Beschreibung</th>
      <th>Erinnerung</th>
      <th>Gültigkeit</th>
      <th class="text-right"><button class="btn pcButton" (click)="onAddClick()"><i *ngIf="!loading; else loadingIcon" class="fas fa-plus"></i></button></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let v of vorlagen">
      <tr [class.inactive]="v.disabled">
        <td>{{v.name}}</td>
        <td>{{v.beschreibung}}</td>
        <td>{{formateErinnerung(v.erinnerungZeit)}}</td>
        <td>{{formatGueltigkeit(v)}}</td>
        <td class="text-right"><button class="btn scButton" (click)="onEditClick(v)"><i *ngIf="!loading; else loadingIcon" class="fas fa-pencil"></i></button></td>
      </tr>
      <ng-container *ngIf="editId === v.id" [ngTemplateOutlet]="editor"></ng-container>
    </ng-container>
    <ng-container *ngIf="!!add" [ngTemplateOutlet]="editor"></ng-container>
    <ng-template #editor>
      <tr>
        <td colspan="4">
          <div class="row">
            <div class="col-6 input-group my-2">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Name</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="editVorlage.name">
            </div>
            <div class="col-6 input-group my-2">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Erinnerung</span>
              </div>
              <select [(ngModel)]="editVorlage.erinnerungZeit" class="form-control">
                <option [value]="ErinnerungZeit.NONE">{{ formateErinnerung(ErinnerungZeit.NONE) }}</option>
                <option [value]="ErinnerungZeit.ON_TIME">{{ formateErinnerung(ErinnerungZeit.ON_TIME) }}</option>
                <option *ngIf="editVorlage.erinnerungZeit === ErinnerungZeit.WEEK_1" [value]="ErinnerungZeit.WEEK_1">{{ formateErinnerung(ErinnerungZeit.WEEK_1) }}</option>
                <option *ngIf="editVorlage.erinnerungZeit === ErinnerungZeit.WEEK_2" [value]="ErinnerungZeit.WEEK_2">{{ formateErinnerung(ErinnerungZeit.WEEK_2) }}</option>
                <option [value]="ErinnerungZeit.MONTH_1">{{ formateErinnerung(ErinnerungZeit.MONTH_1) }}</option>
                <option [value]="ErinnerungZeit.MONTH_2">{{ formateErinnerung(ErinnerungZeit.MONTH_2) }}</option>
                <option [value]="ErinnerungZeit.MONTH_3">{{ formateErinnerung(ErinnerungZeit.MONTH_3) }}</option>
                <option [value]="ErinnerungZeit.MONTH_4">{{ formateErinnerung(ErinnerungZeit.MONTH_4) }}</option>
                <option [value]="ErinnerungZeit.MONTH_5">{{ formateErinnerung(ErinnerungZeit.MONTH_5) }}</option>
                <option [value]="ErinnerungZeit.MONTH_6">{{ formateErinnerung(ErinnerungZeit.MONTH_6) }}</option>
              </select>
            </div>

            <div class="col-12 col-md-6 input-group my-2">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Gültigkeit</span>
              </div>
              <input type="text" [disabled]="!editVorlage.gueltigkeitsdauerUnit" class="form-control" [(ngModel)]="editVorlage.gueltigkeitsdauerVal">
              <div class="input-group-prepend">
                <select [(ngModel)]="editVorlage.gueltigkeitsdauerUnit" class="form-control" (change)="editVorlage.gueltigkeitsdauerVal = (!editVorlage.gueltigkeitsdauerUnit ? 0 : editVorlage.gueltigkeitsdauerVal)">
                  <option [value]="''">---</option>
                  <option [value]="GueltigkeitsdauerUnit.TAG">Tag/e</option>
                  <option [value]="GueltigkeitsdauerUnit.MONAT">Monat/e</option>
                  <option [value]="GueltigkeitsdauerUnit.WOCHE">Woche/n</option>
                  <option [value]="GueltigkeitsdauerUnit.JAHR">Jahr/e</option>
                </select>
              </div>
            </div>

            <div class="col-6 input-group my-2">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Dateiupload</span>
              </div>
              <select [(ngModel)]="editVorlage.uploadRequirement" class="form-control">
                <option [value]="UploadRequirement.NEVER">Nicht benötigt</option>
                <option [value]="UploadRequirement.OPTIONAL">Optional möglich</option>
                <option [value]="UploadRequirement.REQUIRED">Notwendig</option>
              </select>
            </div>

            <div class="col-12 input-group my-2">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Beschreibung</span>
              </div>
              <textarea type="text" class="form-control" [(ngModel)]="editVorlage.beschreibung"></textarea>
            </div>
            <div class="col-12 input-group my-2">
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="editVorlage.disabled">
                  Nachweisvorlage für neue Nachweise deaktivieren.
                </label>
              </div>
            </div>
          </div>
        </td>
        <td class="text-right"><button class="btn pcButton" (click)="onSaveClick()"><i class="fas fa-save"></i></button></td>
      </tr>
    </ng-template>
    </tbody>
  </table>
</div>
<ng-template #loadingIcon>
  <i class="fas fa-spin fa-circle-notch"></i>
</ng-template>
