import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ITeilnehmer} from "../../../models/ITeilnehmer.interface";
import {formateErinnerung, formatGueltigkeit, INachweis} from "../../../models/INachweis.interface";
import {addGueltigkeitsdauer, subtractGueltigkeitsdauer} from "../../../models/gueltigkeitsdauerUnit.enum";
import {UploadRequirement} from "../../../models/uploadRequirement.enum";
import {ErinnerungZeit, getErinnerungDate} from "../../../models/erinnerungZeit.enum";
import {INachweisvorlage} from "../../../models/INachweisvorlage.interface";
import {NachweisvorlageService} from "../../../service/nachweisvorlage.service";
import {NachweisService} from "../../../service/nachweis.service";
import {ToastrService} from "ngx-toastr";
import {EventQueueService} from "../../../service/event-queue-service.service";
import {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmWindowComponent} from "../../../utils/confirm-window/confirm-window.component";
import {TeilnehmerService} from "../../../service/teilnehmer.service";
import {DownloadService} from "../../../service/download.service";

@Component({
  selector: 'app-edit-nachweise',
  templateUrl: './edit-nachweise.component.html',
  styleUrls: ['./edit-nachweise.component.scss']
})
export class EditNachweiseComponent implements OnInit, OnDestroy{
  public readonly formateErinnerung = formateErinnerung;
  @Input()
  public tn: ITeilnehmer;
  @Input()
  public readonly!: boolean;

  public loading = false;
  public details?: number
  public create = false;
  public editId?: number
  public editItem?: INachweis;
  public editFile?: string;
  public vorlagen: INachweisvorlage[];
  public setValidUntil = false;
  private fileCache?: Promise<string>
  private fileCacheId: number = -1;

  public readonly UploadRequirement = UploadRequirement;
  public readonly ErinnerungZeit = ErinnerungZeit;
  public sortedNachweise!: INachweis[];
  private subscription: Subscription;
  private dateError: boolean;
  private dateErrorCheck: boolean;

  constructor(
    private readonly vorlagenService: NachweisvorlageService,
    private readonly nachweisService: NachweisService,
    private readonly toastr: ToastrService,
    private readonly eventService: EventQueueService,
    private readonly ngbModal: NgbModal,
    private readonly tnService: TeilnehmerService,
    private readonly downloadService: DownloadService
) {
  }

  ngOnInit(): void {
    this.subscription = this.eventService.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.init()
      }
    });
    this.init();
  }

  init() {
    this.loading = false;
    this.vorlagen = this.vorlagenService.getNachweisvorlageList()
    this.sortedNachweise = [];
    for(const n of this.tnService.getTn(this.tn.id).nachweise) {
      if(n && n.id) {
        this.sortedNachweise.push(n);
      }
    }
    this.sortedNachweise.sort((v1, v2) => {
      const abgelaufen1 = this.isAbgelaufen(v1);
      const abgelaufen2 = this.isAbgelaufen(v2);

      if(abgelaufen1 !== abgelaufen2) {
        return abgelaufen1 ? 1 : -1
      }

      return v1.ausstellungsdatum.getTime() - v2.ausstellungsdatum.getTime();
    })
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onAddClick() {
    this.editFile = undefined;
    this.editId = -1;
    this.create = true;
    this.editItem = {
      id: -1,
      dateiname: "",
      vorlage: this.vorlagen[0],
      bemerkung: "",
      ausstellungsdatum: new Date(),
      erstellt: new Date(),
      teilnehmer: this.tn
    }
  }

  onEditClick(nachweis: INachweis) {
    this.editFile = undefined;
    this.editId = nachweis.id;
    this.create = false;
    this.editItem = {
      id: nachweis.id,
      dateiname: nachweis.dateiname,
      vorlage: nachweis.vorlage,
      bemerkung: nachweis.bemerkung,
      ausstellungsdatum: nachweis.ausstellungsdatum,
      erstellt: nachweis.erstellt,
      teilnehmer: nachweis.teilnehmer
    }
  }

  async download(nachweis: INachweis, $event: MouseEvent) {
    let btn = $event.target as HTMLButtonElement;
    btn.disabled = true;
    let fileuri = await this.getFile(nachweis);
    this.downloadService.downloadURI(fileuri,nachweis.dateiname);
    btn.disabled = false;
  }

  getFile(nachweis: INachweis): Promise<string> {
    if(this.fileCacheId !== nachweis.id) {
      this.fileCacheId = nachweis.id;
      this.fileCache = this.nachweisService.getNachweisDokument(nachweis);
    }
    return this.fileCache;
  }

  async save() {
    if(this.dateError || this.dateErrorCheck) {
      this.toastr.warning("Ungültiges Datum", "Fehler");
    }
    this.loading = true;
    try {
      let msg;
      if(this.create) {
        msg = await this.nachweisService.createNachweis(this.editItem, this.editFile)
      } else {
        msg = await this.nachweisService.updateNachweis(this.editItem, this.editFile)
      }
      this.toastr.success(msg);
      this.create = false;
    } catch (err) {
      if(typeof err === "string") {
        this.toastr.error(err);
      } else {
        console.log(err);
        this.toastr.error("Fehler");
      }
    }
  }

  public async handleFileUpload(files: FileList){
    if(files.length !== 1){
      return;
    }

    this.editItem.dateiname = files[0].name;

    this.editFile = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = (e.target as any).result as string;
        resolve(dataURL);
      };
      reader.readAsDataURL(files[0]);
    });
  }

  endDateRaw(nachweis: INachweis): Date {
    if(nachweis.vorlage.gueltigkeitsdauerVal <= 0) {
      return new Date(9999, 12, 31, 23, 59, 59);
    }
    return addGueltigkeitsdauer(nachweis.ausstellungsdatum, nachweis.vorlage.gueltigkeitsdauerUnit as any, nachweis.vorlage.gueltigkeitsdauerVal)
  }

  endDate(nachweis: INachweis): string {
      if(nachweis.vorlage.gueltigkeitsdauerVal <= 0) {
        return "---";
      }
      return this.endDateRaw(nachweis).toLocaleDateString('de-DE');
  }



  dauer(vorlage: INachweisvorlage) {
    if(vorlage.gueltigkeitsdauerVal <= 0) {
      return "Unbefristet"
    }
    return formatGueltigkeit(vorlage);
  }

  uploadRequired(vorlage: INachweisvorlage) {
    switch (vorlage.uploadRequirement){
      case UploadRequirement.REQUIRED:
        return "Notwendig";
      case UploadRequirement.OPTIONAL:
        return "Optional";
      case UploadRequirement.NEVER:
        return "Nicht möglich";
    }
  }

  private formatDate(date: Date): string {
      const year = ("000" + date.getFullYear()).slice(-4);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
  }

  get ausstellungsdatum(): string {
      return this.formatDate(this.editItem.ausstellungsdatum);
  }

  get dateCheckData(): string {
      return this.formatDate(this.editItem.erstellt);
  }

  set dateCheckData(val: string) {
      if (val === "") {
          this.dateErrorCheck = true;
          return;
      }
      this.dateErrorCheck = false;
      this.editItem.erstellt = new Date(val);
  }

  get dateInputData(): string {
      let date: Date;
      if (this.setValidUntil) {
          date = this.endDateRaw(this.editItem)
      } else {
          date = new Date(this.editItem.ausstellungsdatum);
      }
      return this.formatDate(date);
  }

  set dateInputData(val: string) {
      if (val === "") {
          this.dateError = true;
          return;
      }
      this.dateError = false;
      const input = new Date(val);
      if(this.setValidUntil && this.editItem.vorlage.gueltigkeitsdauerUnit) {
        this.editItem.ausstellungsdatum = subtractGueltigkeitsdauer(input, this.editItem.vorlage.gueltigkeitsdauerUnit, this.editItem.vorlage.gueltigkeitsdauerVal);
      } else {
        this.editItem.ausstellungsdatum = input;
      }
  }

  isAbgelaufen(nachweis: INachweis) {
    return this.endDateRaw(nachweis).getTime() < Date.now();
  }

  isErinnerung(nachweis: INachweis) {
    const end = this.endDateRaw(nachweis);
    const erinnerungDate = getErinnerungDate(end, nachweis.vorlage.erinnerungZeit)
    return Date.now() >= erinnerungDate.getTime();
  }

  delete(nachweis: INachweis) {
    const activeModal = this.ngbModal.open(ConfirmWindowComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.titel = "Nachweis löschen?";
    activeModal.componentInstance.text = "Soll der Nachweis gelöscht werden?";
    activeModal.result.then(async (result) => {
      if(result){
        try{
          await this.nachweisService.deleteNachweis(nachweis);
          this.toastr.success("Nachweis wurde gelöscht", "Erfolgreich");
        } catch (error) {
          this.toastr.error(error, "Fehler");
        }
      } else {
        this.toastr.info("Nachweis wurde nicht gelöscht.", "Abgebrochen");
      }
    }).catch(()=>{
      this.toastr.info("Nachweis wurde nicht gelöscht.", "Abgebrochen");
    });
  }

    protected readonly addGueltigkeitsdauer = addGueltigkeitsdauer;
}
