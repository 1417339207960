import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//Die nächste Zeile nicht anfassen, die wird von der Buildpipeline
//mit suchen und ersetzen verändert. NICHT ANFASSEN!
const buildId = "191";

async function startApplication() {

  const xhr = new XMLHttpRequest();
  xhr.open("GET", "../../../assets/BUILD_ID?time=" + (new Date()).getTime(), true);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
      console.log("Code Version: " + buildId);
      console.log("Server Version: " + xhr.responseText);
      if (xhr.responseText === buildId) {
        if (environment.production) {
          enableProdMode();
        }
        platformBrowserDynamic().bootstrapModule(AppModule)
          .catch(err => console.error(err));
      } else {
        document.getElementById("loading").style.display = "none";
        document.getElementById("updateInfo").style.display = "initial";
      }
    }
  };
  xhr.send();
}

startApplication();
